/* eslint-disable */
import { createStore } from 'vuex';

export default createStore({
  state: {
    user: null, // ตัวแปรเก็บข้อมูลผู้ใช้ที่ login สำเร็จ
  },
  getters: {
    isLoggedIn: state => !!state.user,
    getUser(state) {
      return state.user;
    }
  },
  mutations: {
    SET_USER(state, userData) {
      state.user = userData;
    },
    setUser(state, user) {
      state.user = user;
    }
  },
  actions: {
    storeUser({ commit }, userData) {
      commit('SET_USER', userData);
    },
  },
  modules: {},
});
