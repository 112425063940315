<template>
    <div class="content-wrapper">
        <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
            <div class="col-sm-12">
                <div class="row">
                <h1 class="m-0 col-8">จัดการ Jobs</h1>
                <!-- <div class="form-group col-4" style="text-align: end">
                    <button class="btn btn-primary" @click="showAddJobModal">Add Job</button>
                    <input type="text" v-model="searchQuery" placeholder="Search..." @input="filterJobs" />
                    <select class="btn btn-primary" v-model="selectedBrand" @change="filterJobs">
                        <option value="">All Brands</option>
                        <option v-for="brand in brands" :key="brand.id" :value="brand.nickname">{{ brand.nickname }}</option>
                    </select>
                </div> -->
            </div>

                <div>
                <table id="jobsTable" class="table table-bordered table-striped">
                    <thead>
                    <tr>
                        <th>ลำดับ</th>
                        <th>Job Name</th>
                        <th>Position</th>
                        <th>Brand Zone</th>
                        <th>Action</th>
                        <th>Candidate</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(job, index) in filteredJobs" :key="job.id">
                        <td>{{ index + 1 }}</td>
                        <td>{{ job.jobname }}</td>
                        <td>{{ job.position }}</td>
                        <td>{{ job.brandZoneNickname }}</td>
                        <td>
                        <button @click="mapJob(job)">Map</button>
                        <button @click="editJob(job)">edit</button>
                        <button @click="deleteJob(job.id)">Delete</button>
                        </td>
                        <td>
                        <span v-for="candidate in job.candidates" :key="candidate.id">
                            <i class="fa fa-user"
                                :class="getCandidateClass(candidate.jobsstep_id)"
                                @click="showCandidate(candidate.id)">
                            </i>
                        </span>
                        </td>
                    </tr>
                    </tbody>
                </table>
                </div>

                <div v-if="showJobModal">
                <div class="popup">
                    <h3>{{ jobModelAction }}</h3>
                    <input type="text" class="form-control" v-model="newJob.jobname" placeholder="Job Name" />
                    <input type="text" class="form-control" v-model="newJob.position" placeholder="Position" />
                    <select class="form-control" v-model="newJob.brand_zone_id">
                    <option v-for="zone in brandZones" :key="zone.id" :value="zone.id">{{ zone.brandNickname }}→{{ zone.nickname }}</option>
                    </select>
                    <button class="btn btn-success" @click="saveJob">Save</button>
                    <button class="btn btn-danger" @click="closeJobModal">Cancel</button>
                </div>
                </div>
            </div>
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
        </div><!-- /.content-header -->
    </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    data() {
        return {
        jobs: [],
        filteredJobs: [],
        brands: [],
        brandZones: [],
        searchQuery: '',
        selectedBrand: '',
        newJob: {
            id:'',
            jobname: '',
            position: '',
            brand_zone_id: ''
        },
        showJobModal: false,
        jobModelAction:'',
        };
    },
    methods: {
        async fetchJobs() {
            try {
                const response = await axios.post('https://www.recruit.i-servreport.com/service/api.php', {
                    req: 'getJobs'
                });
                this.jobs = response.data;
                this.filteredJobs = this.jobs;
                this.initDataTable();
            } catch (error) {
                console.error('Error fetching jobs:', error);
            }
        },
        async fetchBrands() {
            try {
                const response = await axios.post('https://www.recruit.i-servreport.com/service/api.php', { req: 'getBrands' });
                this.brands = response.data;
            } catch (error) {
                console.error('Error fetching brands:', error);
            }
        },
        async fetchBrandZones() {
            try {
                const response = await axios.post('https://www.recruit.i-servreport.com/service/api.php', { req: 'getBrandZones' });
                this.brandZones = response.data;
            } catch (error) {
                console.error('Error fetching brand zones:', error);
            }
        },
        filterJobs() {
            this.filteredJobs = this.jobs.filter(job => {
                return (this.selectedBrand === '' || job.brandNickname === this.selectedBrand) &&
                        (this.searchQuery === '' || job.jobname.toLowerCase().includes(this.searchQuery.toLowerCase()));
            });
        },
        initDataTable() {
            if ($.fn.DataTable.isDataTable('#jobsTable')) {
                $('#jobsTable').DataTable().destroy();
            }
            this.$nextTick(() => {
                $('#jobsTable').DataTable({
                responsive: true, 
                lengthChange: false, 
                autoWidth: false,
                searching: false,
                buttons: ['copy', 'excel', 'print'],
                }).buttons().container().appendTo('#jobsTable_wrapper .col-md-6:eq(0)');
                
                // เพิ่มปุ่มและช่องค้นหาเข้าไปใน DataTables
                $('#jobsTable_wrapper .col-md-6:eq(1)').append(`
                    <div class="form-group" style="display: flex; justify-content: flex-end; align-items: center; margin-left: auto;">
                        <button class="form-control btn btn-info" id="addJobButton">Add Job</button>
                        <select class="form-control btn btn-info" id="brandSelect" style="margin-left: 10px;">
                            <option value="">All Brands</option>
                            ${this.brands.map(brand => `<option value="${brand.nickname}">${brand.nickname}</option>`).join('')}
                        </select>
                        <input type="text" class="form-control" id="searchInput" placeholder="Search Job Name" style="margin-left: 10px;" />
                    </div>
                `);

                // ตั้งค่า event listeners สำหรับปุ่มและช่องค้นหา
                // $('#addJobButton').on('click', this.showAddJobModal);
                $('#addJobButton').on('click',()=>{
                    this.showAddJobModal();
                    this.jobModelAction = 'Add Job';
                    this.newJob.id ='';
                    this.newJob.jobname ='';
                    this.newJob.position = '';
                    this.newJob.brand_zone_id ='';
                });
                $('#searchInput').on('input', (event) => {
                    this.searchQuery = event.target.value;
                    this.filterJobs();
                });
                $('#brandSelect').on('change', (event) => {
                    this.selectedBrand = event.target.value;
                    this.filterJobs();
                });
            });
        },
        showAddJobModal() {
        this.showJobModal = true;
        },
        closeJobModal() {
        this.showJobModal = false;
        },
        async saveJob() {
            let reqAct ='';
            if(this.jobModelAction ==='Add Job') {
                reqAct = 'addJob'
            } else if (this.jobModelAction ==='edit Job') {
                reqAct = 'editJob'
            }
            console.log (reqAct);
            try {
                await axios.post('https://www.recruit.i-servreport.com/service/api.php', {
                req: reqAct,
                ...this.newJob,
                });
                this.fetchJobs();
                this.closeJobModal();
                Swal.fire('Success', 'Job added successfully', 'success');
            } catch (error) {
                console.error('Error adding job:', error);
                Swal.fire('Error', 'Error adding job', 'error');
            }
        },
        async deleteJob(id) {
            await Swal.fire({
                title: "คุณต้องการลบตำแหน่งงานนี้ ?",
                // text: "คุณต้องการลบข้อมูลงานใชไหม",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!"
                }).then((result) => {
                if (result.isConfirmed) {
                    try {
                        axios.post('https://www.recruit.i-servreport.com/service/api.php', {
                            req: 'deleteJob',
                            id,
                        });
                        Swal.fire('Deleted!', 'Job deleted successfully', 'success');
                        this.fetchJobs();
                    } catch (error) {
                        console.error('Error deleting job:', error);
                        Swal.fire('Error', 'Error deleting job', 'error');
                    }
                }
            });
        },
        editJob(job) {
            console.log(job);
            this.showAddJobModal();
            this.newJob.id = job.id;
            this.newJob.jobname = job.jobname;
            this.newJob.position = job.position;
            this.newJob.brand_zone_id = job.brand_zone_id;
            this.jobModelAction = 'edit Job';
        },
        mapJob(job) {
        // Add logic to map job with candidate
        },
        showCandidate(id) {
        // Add logic to show candidate information
        },
        getCandidateClass(jobsstep_id) {
        switch(jobsstep_id) {
            case 1: return 'text-warning';
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7: return 'text-success';
            case 8: return 'text-info';
            default: return '';
        }
        }
    },
    mounted() {
        this.fetchJobs();
        this.fetchBrands();
        this.fetchBrandZones();
    }
}
</script>

<style scoped>
.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    z-index: 1000;
}
.fa-user {
    cursor: pointer;
    margin: 0 5px;
}
</style>
