<template>
<footer class="main-footer">
    <!-- To the right -->
    <div class="float-right d-none d-sm-block">
    Version 1.0.2
    <!-- Anything you want &and; Everything for you -->
    </div>
    <!-- Default to the left -->
    <strong>Copyright &copy; {{ getCurrentYear() }} <a href="#"> </a> </strong> All rights
    reserved.
</footer>
</template>

<script>
/* eslint-disable */
export default {
    name: 'PageFooter', // ตั้งชื่อคอมโพเนนท์เป็น multi-word เช่น 'PageFooter'
    methods: {
        getCurrentYear() {
            const currentDate = new Date();
            return currentDate.getFullYear();
        },
    },
}
</script>
