<template>
  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"
          ><i class="fas fa-bars"></i
        ></a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a href="/" class="nav-link">Home</a>
      </li>
    </ul>
    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <!-- Notifications Dropdown Menu -->
      <!--
            <li class="nav-item dropdown">
                <a class="nav-link" data-toggle="dropdown" href="#">
                    <i class="far fa-bell"></i>
                    <span class="badge badge-warning navbar-badge">15</span>
                </a>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <span class="dropdown-item dropdown-header">15 Notifications</span>
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item">
                    <i class="fas fa-envelope mr-2"></i> 4 new messages
                    <span class="float-right text-muted text-sm">3 mins</span>
                </a>
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item">
                    <i class="fas fa-users mr-2"></i> 8 friend requests
                    <span class="float-right text-muted text-sm">12 hours</span>
                </a>
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item">
                    <i class="fas fa-file mr-2"></i> 3 new reports
                    <span class="float-right text-muted text-sm">2 days</span>
                </a>
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a>
                </div>
            </li>
        -->
      <li class="nav-item">
        <a class="nav-link" data-widget="fullscreen" href="#" role="button">
          <i class="fas fa-expand-arrows-alt"></i>
        </a>
      </li>
      <!-- user (optional) -->
      <!-- <li class="nav-item">
        <div class="btn-group">
            <button type="button" class="btn dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            style="padding-top: 0px; padding-bottom: 0px; padding-right: 0px; padding-left: 0px;" >
                <div class="user-panel  pb-0 mb-0 d-flex dropdown">
                    <div class="image">
                        <img src="asset/dist/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image">
                    </div>
                    <div class="info">
                        <a href="#" class="d-block">ชื่อผู้ใช้งาน</a>
                    </div>
                </div>
            </button>
            <div class="dropdown-menu dropdown-menu-right">
                <button class="dropdown-item" type="button">ข้อมูลผู้ใช้</button>
                <button class="dropdown-item" type="button">เข้าสู่ระบบ</button>
                <button class="dropdown-item" href="asset/function/logout.php" type="button">ออกจากระบบ</button>
            </div>
        </div>
        </li> -->

      <li class="nav-item">
        <div class="btn-group">
          <a
            href="#"
            class="btn dropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            style="
              padding-top: 0px;
              padding-bottom: 0px;
              padding-right: 0px;
              padding-left: 0px;
            "
          >
            <div class="user-panel pb-0 mb-0 d-flex dropdown">
              <div class="image">
                <img
                  src="/dist/img/Sample_User_Icon.png"
                  class="img-circle elevation-2"
                  alt="User Image"
                />
              </div>
              <div class="info">
                <span class="d-block" v-if="user">{{ user.username }} </span>
              </div>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <a class="dropdown-item" href="#">ข้อมูลผู้ใช้</a>
            <button class="dropdown-item" @click="logout">ออกจากระบบ</button>
          </div>
        </div>
      </li>
    </ul>
  </nav>
  <!-- /.navbar -->
  <!-- Main Sidebar Container -->
<aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="/" class="brand-link">
      <img
        src="../assets/logo.png"
        alt="I-SERV Report Logo"
        class="brand-image img-circle elevation-3"
      />
      <span class="brand-text font-weight-light">I-SERV Recruit</span>
    </a>
    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >

          <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="nav-icon fas fa-address-card"></i>
              <p>
                ผู้สมัครงาน
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <a href="/applicant-list" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>รายชื่อผู้สมัคร</p>
                </a>
              </li>
              <li class="nav-item">
                <a href="/applicant-add" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>เพิ่มผู้สมัครใหม่</p>
                </a>
              </li>

            </ul>
          </li>
          <!-- Google Form Report -->
          <!--
            <li class="nav-item">
              <a href="#" class="nav-link">
                <i class="nav-icon fas fa-edit"></i>
                <p>Google Form Report</p>
              </a>
            </li>
          -->

          <li class="nav-header">จัดการระบบ</li>
          <!-- <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="nav-icon fas fa-users"></i>
              <p>พนักงาน</p>
            </a>
          </li> -->
          <li class="nav-item">
            <a href="/jobs" class="nav-link">
              <i class="nav-icon fas fa-portrait"></i>
              <p>จัดการงาน</p>
            </a>
          </li>

          <li class="nav-item">
            <a href="/manage-area" class="nav-link">
              <i class="nav-icon far fa-map"></i>
              <p>จัดการ Brand - Zone</p>
            </a>
          </li>

          <li class="nav-item">
            <a @click="pushImagesGallay" class="nav-link">
              <i class="nav-icon fas fa-images"></i>
              <p>รูปผู้สมัคร</p>
            </a>
          </li>
        </ul>
      </nav><!-- /.sidebar-menu -->
    </div><!-- /.sidebar -->
</aside>
</template>

<script>
/* eslint-disable */
import { mapState } from 'vuex';
import UserService from '@/services/UserService';
export default {
  name: 'MenuBar',
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  methods: {
    logout() {
      UserService.logout();
    },
    pushImagesGallay () {
      this.$router.push('/image-gallery');
    },
    pushApplicantAdd () {
      this.$router.push('/applicant-add');
    },
    pushApplicantList () {
      this.$router.push('/applicant-list');
    },
  }
}
</script>
<style scoped>
.nav-link {
  cursor: pointer;
}
</style>
