<template>
  <div>
    <input v-model="searchQuery" placeholder="Search images...">
    <table>
      <thead>
        <tr>
          <th>Id</th>
          <th>Candidate Id</th>
          <th>File Name</th>
          <th>File Path</th>
          <th>File Size</th>
          <th>File Type</th>
          <th>Uploaded On</th>
          <th>Users Id</th>
          <th>Subject</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="image in filteredImages" :key="image.id">
          <td>{{ image.id }}</td>
          <td>{{ image.candidate_id }}</td>
          <td>{{ image.file_name }}</td>
          <td>{{ image.file_path }}</td>
          <td>{{ image.file_size }}</td>
          <td>{{ image.file_type }}</td>
          <td>{{ image.uploaded_on }}</td>
          <td>{{ image.users_id }}</td>
          <td>{{ image.subject }}</td>
          <td>
            <!-- <button @click="showImage(image.file_path)">View</button> -->
            <button @click="showImage(image.file_name)">View</button>
            <button @click="deleteImage(image.id, image.file_path)">Delete</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="showPopup" class="popup" @click.self="closePopup">
      <span class="close" @click="closePopup">&times;</span>
      <img :src="currentImage" alt="Image">
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';

export default {
  data() {
    return {
      images: [],
      showPopup: false,
      currentImage: '',
      searchQuery: ''
    };
  },
  computed: {
    filteredImages() {
      return this.images.filter(image => 
        Object.values(image).some(value => 
          String(value).toLowerCase().includes(this.searchQuery.toLowerCase())
        )
      );
    }
  },
  methods: {
    async fetchImages() {
      try {
        const response = await axios.post('https://www.recruit.i-servreport.com/service/api.php', {
          req: 'Allimages'
        });
        this.images = response.data;
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    },
    // showImage(filePath) {
    //   this.currentImage =filePath;
    //   this.showPopup = true;
    // },
    showImage(fileName) {
      this.currentImage ='https://www.recruit.i-servreport.com/uploads/'+fileName;
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
      this.currentImage = '';
    },
    async deleteImage(id, filePath) {
      try {
        await axios.post('https://www.recruit.i-servreport.com/service/api.php', {
          req: 'delete-image',
          id: id,
          filePath: filePath
        });
        this.images = this.images.filter(image => image.id !== id);
      } catch (error) {
        console.error('Error deleting image:', error);
      }
    }
  },
  mounted() {
    this.fetchImages();
  }
};
</script>

<style scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.popup img {
  max-width: 80%;
  max-height: 80%;
}
.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}
</style>
