import { createRouter, createWebHistory } from 'vue-router'
import MainView from '../views/MainView.vue'
import ImageGallery from '@/views/ImageGallery.vue'
import ManageArea from '@/views/ManageArea.vue'
import Jobs from '@/views/ManageJobs.vue'
// import ApplicantView from '../views/ApplicantView.vue'
// import LoginView from '../views/Login.vue'
// import RegisterView from '../views/RegisterView'

const routes = [
  {
    path: '/',
    name: 'home',
    component: MainView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
    // component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/RegisterView')
  },
  {
    path: '/reset',
    name: 'reset',
    component: () => import('@/views/ForgotPassword')
  },
  {
    path: '/applicant-add',
    name: 'applicant-add',
    component: () => import('@/views/ApplicantAdd')
  },
  {
    path: '/applicant-list',
    name: 'applicant-list',
    component: () => import('@/views/ApplicantList')
  },
  {
    path: '/image-gallery',
    name: 'ImageGallery',
    component: ImageGallery
  },
  {
    path: '/manage-area',
    name: 'manage-area',
    component: ManageArea
  },
  {
    path: '/jobs',
    name: 'manage-jobs',
    component: Jobs
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
