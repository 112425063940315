<template>
    <div class="wrapper">
        <MenuBar />
        <QuotaJobs />
        <PageFooter />
    </div>
</template>

<script>
/* eslint-disable */
import { mapState } from 'vuex';
// import axios from 'axios';
import UserService from '@/services/UserService';
import MenuBar from '@/components/MenuBar.vue'
import PageFooter from '@/components/PageFooter.vue';
import QuotaJobs from '../components/QuotaJobs.vue';

export default {
    name:'ManageArea',
    computed: {
        ...mapState({
        user: state => state.user
        })
    },
    components: {
        MenuBar,
        QuotaJobs,
        PageFooter
    },
    data() {
      return {
        cookieLogin: document.cookie,
      };
    },
    mounted() {
        document.body.className = 'sidebar-mini layout-navbar-fixed',
        UserService.checkLoginStatus();
    }
};
</script>

<style scoped>
.body{
    height: auto
}
</style>