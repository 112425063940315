<!-- ContentBlank.vue -->
<template>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">blank content</h1>
            <p>{{ msg }}</p> <!-- Display the 'msg' prop here -->
            <div>
              <p v-if="user">Welcome, {{ user.firstname }}!</p>
            </div>
          </div>
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div><!-- /.content-header -->
  </div>
</template>

<script>
/* eslint-disable */
import { mapState } from 'vuex';
export default {
  name: 'ContentBlank',
  computed: {
    ...mapState({
      user: state => state.user
    })
  }
}
</script>


<style scoped>
/* Scoped CSS */
</style>
