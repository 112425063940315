/* eslint-disable */
import axios from 'axios';
import store from '@/store';
import router from '@/router';

export default {
  async checkLoginStatus() {
    // ตรวจสอบว่ามีข้อมูลผู้ใช้ใน store หรือไม่
    if (!store.state.user) {
      // ถ้าไม่มี ให้ตรวจสอบ cookie ชื่อ cookieLogin
      const cookieLogin = this.getCookie('cookieLogin');
      if (cookieLogin) {
        try {
          // ส่งค่าไปตรวจสอบที่ api.php
          const response = await axios.post('https://www.recruit.i-servreport.com/service/api.php', {
            req: 'checkLoginStatus',
            cookieLogin: cookieLogin
          });

          if (response.data.status === 'success') {
            // ถ้าการตรวจสอบสำเร็จ ให้เก็บข้อมูลผู้ใช้ใน store และตั้งค่า cookie ใหม่
            store.commit('setUser', response.data.user);
            this.setCookie('cookieLogin', response.data.user.cookieLogin, 15);
            return true;
          } else {
            // ถ้าไม่สำเร็จ ให้ย้ายไปหน้า /login
            router.push('/login');
            return false;
          }
        } catch (error) {
          console.error('Error checking login status:', error);
          router.push('/login');
          return false;
        }
      } else {
        // ถ้าไม่มี cookie ให้ย้ายไปหน้า /login
        router.push('/login');
        return false;
      }
    }
    return true; // มีข้อมูลผู้ใช้ใน store อยู่แล้ว
  },

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  },

  setCookie(name, value, days) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = `${name}=${value || ""}${expires}; path=/; SameSite=None; Secure`;
  },

  deleteCookie(name) {
    document.cookie = name + '=; Max-Age=-99999999;';
  },

  logout() {
    this.deleteCookie('cookieLogin');
    store.commit('setUser', null);
    router.push('/login');
  }
}
