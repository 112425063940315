<template>
    <div class="wrapper">
        <MenuBar />
        <ContentBlank />

        <PageFooter />
        <!-- <MenuBar :msg="massage_menubar"/>
        <ContentBlank :msg="message_content" />

        <PageFooter :msg="message_footer" /> -->
    </div>
</template>

<script>
/* eslint-disable */
import { mapState } from 'vuex';
// import axios from 'axios';
import UserService from '@/services/UserService';
import MenuBar from '@/components/MenuBar.vue'
import ContentBlank from '@/components/ContentBlank.vue';
import PageFooter from '@/components/PageFooter.vue';

export default {
    name:'MainPage',
    computed: {
        ...mapState({
        user: state => state.user
        })
    },
    components: {
        MenuBar,
        ContentBlank,
        PageFooter
    },
    data() {
      return {
        cookieLogin: document.cookie,
        massage_MenuBar: 'ข้อความที่ต้องการส่งไปยัง MenuBar component',
        message_content: 'ข้อความที่ต้องการส่งไปยัง ContentBlank component',
        message_footer: 'ข้อความที่ต้องการส่งไปยัง footer component',
      };
    },
    mounted() {
        document.body.className = 'sidebar-mini layout-navbar-fixed'
        UserService.checkLoginStatus();
    }
};
</script>

<style scoped>
.body{
    height: auto
}
</style>