<template>
    <div class="wrapper">
        <MenuBar />
            <div class="content-wrapper">
            <!-- Content Header (Page header) -->
            <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0">Image Gallery</h1>
                    <!-- <p>{{ msg }}</p> -->
                    <div>
                        <ImageTable />
                    </div>
                </div>
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
            </div><!-- /.content-header -->
        </div>

        <PageFooter />
        <!-- <MenuBar :msg="massage_menubar"/>
        <ContentBlank :msg="message_content" />

        <PageFooter :msg="message_footer" /> -->
    </div>
</template>

<script>
/* eslint-disable */
import { mapState } from 'vuex';
import axios from 'axios';
import UserService from '@/services/UserService';
import MenuBar from '@/components/MenuBar.vue'
import PageFooter from '@/components/PageFooter.vue';
import ImageTable from '@/components/ImageTable.vue';

export default {
    name:'MainPage',
    computed: {
        ...mapState({
        user: state => state.user
        })
    },
    components: {
        MenuBar,
        ImageTable,
        PageFooter
    },
    data() {
      return {
        cookieLogin: document.cookie,
        // massage_MenuBar: 'ข้อความที่ต้องการส่งไปยัง MenuBar component',
        // message_content: 'ข้อความที่ต้องการส่งไปยัง ContentBlank component',
        // message_footer: 'ข้อความที่ต้องการส่งไปยัง footer component',
        userid:'',
      };
    },

    mounted() {
        document.body.className = 'sidebar-mini layout-navbar-fixed',
        UserService.checkLoginStatus();
    },
}

</script>

<style scoped>
.body{
    height: auto
}
</style>

