<template>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="row">
              <h1 class="m-0 col-8">จัดการ AREA</h1>
              <div class="form-group col-4" style="text-align: end">
                <button class="btn btn-primary" @click="showAddBrandModal">Add Brand</button>
                <button class="btn btn-success" @click="showAddZoneModal">Add Zone</button>
              </div>
            </div>

            <div>
              <table id="brandZoneTable" class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>ลำดับ</th>
                    <th>แบรนด์</th>
                    <th>เขต</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in brandZones" :key="item.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.brandNickname }}</td>
                    <td>{{ item.zoneNickname }}</td>
                    <td>
                      <button @click="editZone(item)">แก้ไข</button>
                      <button @click="deleteZone(item.id)">ลบ</button>
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>
              <div v-if="showBrandModal">
                <div class="popup">
                  <h3>Add Brand</h3>
                  <input type="text" v-model="brand.nickname" placeholder="ชื่อย่อแบรนด์" />
                  <input type="text" v-model="brand.fullname" placeholder="ชื่อเต็มแบรนด์" />
                  <button @click="saveBrand">บันทึก</button>
                  <button @click="closeBrandModal">ยกเลิก</button>
                </div>
              </div>

              <div v-if="showZoneModal">
                <div class="popup">
                  <h3>Add Zone</h3>
                  <select v-model="zone.brand_id">
                    <option v-for="brand in brands" :key="brand.id" :value="brand.id">
                      {{ brand.nickname }}
                    </option>
                  </select>
                  <input type="text" v-model="zone.nickname" placeholder="ชื่อย่อเขต" />
                  <input type="text" v-model="zone.fullname" placeholder="ชื่อเต็มเขต" />
                  <button @click="saveZone">บันทึก</button>
                  <button @click="closeZoneModal">ยกเลิก</button>
                </div>
              </div>
          </div>
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div><!-- /.content-header -->
  </div>
</template>

<script>
/* eslint-disable */
import { mapState } from 'vuex';
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      brands: [],
      brandZones: [],
      brand: { nickname: '', fullname: '' },
      zone: { brand_id: '', nickname: '', fullname: '' },
      showBrandModal: false,
      showZoneModal: false,
      editMode: false,
    };
  },
  methods: {
    async fetchBrands() {
      try {
        const response = await axios.post('https://www.recruit.i-servreport.com/service/api.php', { req: 'getBrands' });
        this.brands = response.data;
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    },
    async fetchBrandZones() {
      try {
        const response = await axios.post('https://www.recruit.i-servreport.com/service/api.php', { req: 'getBrandZones' });
        console.log(response);
        this.brandZones = response.data.map(item => ({
          ...item,
          brandNickname: item.brandNickname,
          zoneNickname: item.nickname,
        }));
        this.initDataTable();
      } catch (error) {
        console.error('Error fetching brand zones:', error);
      }
    },
    initDataTable() {
      this.$nextTick(() => {
        if ($.fn.DataTable.isDataTable('#brandZoneTable')) {
          $('#brandZoneTable').DataTable().destroy();
        }
        $('#brandZoneTable').DataTable({
          responsive: true, 
          lengthChange: false, 
          autoWidth: false,
          buttons: ['copy', 'excel', 'print'],
        }).buttons().container().appendTo('#brandZoneTable_wrapper .col-md-6:eq(0)');
      });

    },
    showAddBrandModal() {
      this.showBrandModal = true;
    },
    showAddZoneModal() {
      this.showZoneModal = true;
    },
    closeBrandModal() {
      this.showBrandModal = false;
    },
    closeZoneModal() {
      this.showZoneModal = false;
    },
    async saveBrand() {
      try {
        let response = await axios.post('https://www.recruit.i-servreport.com/service/api.php', {
          req: 'addBrand',
          ...this.brand,
        });
        this.fetchBrands();
        this.closeBrandModal();
        if (response.data.status === 'error') {
          Swal.fire({
            position: "center",
            icon: "warning",
            title: 'ชื่อย่อ Brand นี้ถูกสร้างแล้ว',
            showConfirmButton: false,
            timer: 1500
          });
        } else if (response.data.status === 'success') {
          Swal.fire({
            position: "center",
            icon: "success",
            title: 'Brand added successfully',
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.brand.fullname ='';
        this.brand.nickname ='';
      } catch (error) {
        console.error('Error adding brand:', error);
        Swal.fire('Error', 'Error adding brand', 'error');
      }
    },
    async saveZone() {
      try {
        await axios.post('https://www.recruit.i-servreport.com/service/api.php', {
          req: 'addZone',
          ...this.zone,
        });
        this.fetchBrandZones();
        this.closeZoneModal();
        Swal.fire('Success', 'Zone added successfully', 'success');
      } catch (error) {
        console.error('Error adding zone:', error);
        Swal.fire('Error', 'Error adding zone', 'error');
      }
    },
    async editZone(item) {
      this.editMode = true;
      this.zone = { ...item };
      this.showZoneModal = true;
    },
    async deleteZone(id) {
      try {
        await axios.post('https://www.recruit.i-servreport.com/service/api.php', {
          req: 'deleteZone',
          id,
        });
        this.fetchBrandZones();
        Swal.fire('Success', 'Zone deleted successfully', 'success');
      } catch (error) {
        console.error('Error deleting zone:', error);
        Swal.fire('Error', 'Error deleting zone', 'error');
      }
    },
  },
  mounted() {
    this.fetchBrands();
    this.fetchBrandZones();
  },
  computed: {
    ...mapState({
      user: state => state.user
    })
  }
}
</script>

<style scoped>
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
</style>
